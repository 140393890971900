<template>
    <v-data-table
        :items-per-page="-1"
        :headers="headers"
        loading-text="Завантаження..."
        no-data-text="Немає даних для відображення"
        :items="pays"
        :group-by="['year']"
        :group-desc="[true]"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        focusable
        hide-default-footer
        :dense="switch_danse_table || $vuetify.breakpoint.smAndDown"
    >
        <template v-slot:top>

            <v-toolbar flat color="secondary lighten-1" dark dense>
                <v-toolbar-title class=""><h3 class="mr-6  accent--text">Акт звірки <small v-if="!$vuetify.breakpoint.mobile && user && (user.username == 'dima' || user.username == 'mitya')">( ID: {{ inetPays ? service_inet_id : service_tv.user_id }} )</small></h3></v-toolbar-title>
                
                <v-spacer/>
                  <v-btn v-if="!inetPays" :outlined="!$vuetify.breakpoint.mobile"  :icon="$vuetify.breakpoint.mobile"  small :color="!inetPays ? 'accent' : 'grey'"
                  :class="!inetPays ? 'primary--text' : ''"
                  >
                    <v-icon v-if="$vuetify.breakpoint.mobile">mdi-television</v-icon> 
                    <span v-else>ТБ</span>
                  </v-btn>
                  <v-btn v-if="inetPays" :outlined="!$vuetify.breakpoint.mobile" :icon="$vuetify.breakpoint.mobile"  small :color="inetPays ? 'accent' : 'grey'"
                  :class="inetPays ? 'primary--text' : ''"
                  class="ml-3"
                  >
                  <v-icon v-if="$vuetify.breakpoint.mobile">mdi-web</v-icon> 
                    <span v-else>Інтернет</span>
                    
                  </v-btn>
                
                <v-spacer />
                <span title="Зменшити таблицю">  
                  <v-switch
                    v-if="!$vuetify.breakpoint.smAndDown"
                    class="ml-2 mr-1"
                    v-model="switch_danse_table"
                    hide-details
                  ></v-switch>
                </span>
                <v-btn 
                icon 
                :loading="loading"
                @click="getPays()">
                <v-icon>autorenew</v-icon>
                </v-btn>
                

                <v-btn
                icon
                title="Повернутись до платежів"
                @click="$emit('view-pays')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
        </template>

        <template v-slot:group.header="{group, items, isOpen, toggle, headers}">
        <td :colspan="headers.length"  @click="toggle">
          <div class="d-flex justify-space-between">
            <!--<span v-if="$vuetify.breakpoint.mdAndUp"></span>-->
            <span style="vertical-align: middle;" >
                <h3 :class="$vuetify.theme.dark ? 'accent--text' : ''">Рік: {{ group }} </h3>
            </span>
            <span style="vertical-align: middle;">
              Залишок на початок: <b> {{ getBalanceStartYear(items)}}</b>
            </span>
            <v-icon right> {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </div>
        </td>
      </template>
        
    <!-- ---------------------------------------- Cols ----------------------------------------- -->
    
    <template  #group.summary="{ isOpen, items, isMobile }">
      <template v-if="isOpen && !isMobile">
        <td><b>Всього:</b></td>
        <td></td>
        <td class="text-end"><b>{{getSummHeaders(items, 'abonplata')}}</b></td>
        <td class="text-end"><b>{{getSummHeaders(items, 'services')}}</b></td>
        <td class="text-end"><b>{{getSummHeaders(items, 'pays')}}</b></td>
        <td class="text-end"></td>
        <br>
      </template>
      
      
    </template>
    <template #item.month="{ item }">
        <span title="Згенеровано">{{ item.month }} <v-icon v-if="!inetPays && dateGenerateAct && isGenerateMount(item)" class="ml-2" color="error">mdi-information-outline</v-icon> </span>
    </template>
    <template #item.balance="{ item, index }">
        <span :class="{'deep-orange--text': Number(item.balance) < 0, 'accent pa-1 rounded outlined': !checkCurrentBalanceYear(item, index)}">{{ item.balance }}</span>
    </template>

    <template #item.abonplata="{ item }">
        <span :class="{'deep-orange--text': item.abonplata < 0}">{{ item.abonplata }}</span>
    </template>

    <template #item.services="{ item }">
        <span :class="{'deep-orange--text': Number(item.services) < 0}">{{ item.services }}</span>
    </template>

    <template #item.pays="{ item }">
        <span :class="{'deep-orange--text': item.pays < 0}">{{ item.pays }}</span>
    </template>

    <template #item.end_balance="{ item }">
        <span :class="{'deep-orange--text': item.end_balance < 0}">{{ item.end_balance }}</span>
    </template>
    
    <!-- ---------------------------------------- /Cols ----------------------------------------- -->
    <template v-slot:footer>
        <v-row v-if="pays.length != 0" justify="center" class="mt-3 pb-5">
            <v-btn v-if="fullCurrentYear" :disabled="no_data_year" :loading="loading" small color="accent" class="primary--text mr-2" @click="getPays(true)">Наступний рік</v-btn>
            <v-btn v-if="!dateGenerateAct && !inetPays" :title="dateGenerateAct" :loading="loading" small color="accent" class="primary--text" @click="getPays2()">Згенерувати</v-btn>
        </v-row>
    </template>
    
    </v-data-table>
</template>

<script>
  
  import axios from "axios"
  export default {
    props: ['abonent', 'user', 'service_tv', 'service_inet', 'inetPays', "viewActPays", "service_inet_id"],
    name: "table-act-pays",
    components: {
      //
    },
    data: () => ({
      switch_danse_table:false,
      monthFormat: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      no_data_year: false,
      now_year: new Date().getFullYear(),
      updateTable: 0,
      pays: [],
      loading: false,
      options: {},
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50},
        { text: 'Рік', value: 'year',  },
        { text: 'Місяць', value: 'month',  },
        { text: 'Залишок на початок', value: 'balance', class: 'text-end', cellClass: 'text-end', sortable: false, },
        { text: 'Абонплата', value: 'abonplata', class: 'text-end', cellClass: 'text-end', sortable: false },
        { text: "Послуги", value: 'services', class: 'text-end', cellClass: 'text-end', sortable: false },
        { text: 'Оплата', value: 'pays', class: 'text-end', cellClass: 'text-end', sortable: false },
        { text: 'Залишок на кінець', value: 'end_balance', class: 'text-end', cellClass: 'text-end', sortable: false },
      ],
    }),
    computed: {
      
      fullCurrentYear(){
        if(this.now_year != new Date().getFullYear()) return true
        if(this.now_year == new Date().getFullYear() && this.pays.length == (new Date().getMonth() + 1)) return true
        return false
      },
      dateGenerateAct(){
        //console.log(this.service_tv)
        return this.service_tv && this.service_tv.generate_act || false
      },
      
    },
    watch: {
      updateTable(){
        this.updateTable && this.getPays();
      },
      viewActPays(val){
        val && this.getPays()
      }
    },
    activated() {
        //this.getPays()
    },
    deactivated(){
      //
    },
    methods: {
      isGenerateMount(item){
        const itemNumberMont = this.monthFormat.indexOf(item.month) + 1
        //console.log(this.dateGenerateAct, `${item.year}-${itemNumberMont}-1`)
        return new Date(this.dateGenerateAct) >= new Date(`${item.year}-${itemNumberMont}-1`)
      },
      getSummHeaders(items, header){
        let listItems = []
        if(header == 'balance') listItems = items.map( x => Number(x[header]))
        else listItems = items.map( x => x[header])
        const total = listItems.reduce( (accumulator, currentValue) => accumulator + currentValue)
        return total
      },
      getBalanceStartYear(items){
        let firstMonth = items.at(-1)
        
        return firstMonth && firstMonth.balance
      },
      checkCurrentBalanceYear(item){
        

        let indexMonthItem = this.pays.indexOf(item)
        let beforeMonth = indexMonthItem != -1 && this.pays[indexMonthItem + 1] || false
        
        if(beforeMonth) {
          //console.log(`${beforeMonth.year}, ${beforeMonth.month}: ${beforeMonth.end_balance} =?`, `${beforeMonth.year}, ${item.month}: ${Number(item.balance)}`)
          return beforeMonth.end_balance == Number(item.balance)

        }
        
        
        
        return true
      },
      formatMemo(memo){
        if(memo.length > 9 ){
          return memo.substr(0, 9) + '...'
        }
        else return memo
      },
      getPaysBilling(year_down=false){
        axios({
          method: "get",
          url: this.$router.app.devUrlBillingAPI,
          params: {req_cmd: "getUserActPays", pcode: this.abonent?.user_pcode, year: this.now_year},
          
        })
        .then( response =>{
          //console.log(response.data)
          if(response.data.resp_status == "OK"){
            
            if(year_down) this.pays.push(...response.data.resp_result)
            else this.pays = response.data.resp_result

            if(response.data.resp_result.length == 0) this.no_data_year = true
          }else if(response.data.resp_status == "REJECT"){
            this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg)
          }
          else{
            
            this.pays = []
          }
        })
        .catch(() => {})
        .finally(() => (this.loading = false)) 
      },
      getPays(year_down=false){
        this.loading = true 
        
        if(year_down) this.now_year -= 1
        else {
          this.no_data_year = false
          this.now_year = new Date().getFullYear()
        }

        if(this.inetPays){
          this.getPaysBilling(year_down)
          return
        }

        axios.get(`/api/cabletv/get_user/${this.$route.params.id}/act_pays/` + (year_down ? `?year=${this.now_year}`: ''))
        .then( response =>{
          //console.log(response.data)
          if(year_down) this.pays.push(...response.data)
          else this.pays = response.data

          if(response.data.length == 0) this.no_data_year = true
        })
        .catch(() => {})
        .finally(() => (this.loading = false)) 
        
      },
      async getPays2(){
        let question = await this.$parent.$refs.confirmDialog.open('Згенерувати залишки?', 'Історія залишків на початок місяця не повна, Акт звірки буде згенеровано відносно платежів абонента за весь період.')
        if(question){

        
        this.loading = true 
        
          axios.post(`/api/cabletv/get_user/${this.$route.params.id}/act_pays/`,)
          .then( response =>{
            //console.log(response.data)
            //this.pays = response.data
            this.$router.app.$snack.show(response.data[0])
            this.$parent.$parent.updateAbonent() // Оновлюємо дані абонента
            this.getPays()
          })
          .catch(() => {})
          .finally(() => (this.loading = false)) 
        }
      },
    } 
  }
</script>
<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title class="">
            <span class="headline">{{ abonentData.title }} </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        <v-spacer />
                        <v-col cols="12" md=12>   
                            
                            <div class="mb-4 text-center">
                                <h4>ТЕЛЕБАЧЕННЯ</h4>
                            </div>
                            
                            <template v-if="showRatesTv || service_tv">
                                <v-col v-for="field in fieldsTv" :key="field.name" cols="12" class="py-2">
                                    <v-select v-if="field.type == 'select'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data_tv[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :items="field.values"
                                    item-text="name"
                                    item-value="id"
                                    :disabled="field.disabled"
                                    @change="eventChangeTarifField()"
                                    >
                                    <template v-if="dialog" v-slot:prepend-item>
                                        <v-subheader style="height: 30px;">Фільтри:</v-subheader>

                                        <FiltersTarifs ref="filtersTarifs" v-on:create-values-rates="createValuesRates()" v-on:aplly-filters-tarifs="apllyFiltersTarifs()" :is_special_tarif_for_distributor="is_special_tarif_for_distributor" service="tv"/>
                                        
                                        <v-divider />
                                    </template>
                                    <template v-if="field.name == 'tarif'" v-slot:item="{ item }">
                                        <div style="width: 100%;" class="d-flex justify-space-between">
                                            <span class="v-list-item__title">{{ item.name }}</span>
                                            <DetailTarifForFilters :tarif="item" />
                                        </div>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-row justify="center" class="mt-2 mb-2" >
                                            <small class="error--text">Тарифи не знайдено</small>
                                        </v-row>
                                    </template>
                                    </v-select>

                                    <v-switch v-else-if="field.type == 'switch'"
                                    dense
                                    hide-details
                                    :label="field.title"
                                    v-model="form_data_tv[field.name]"
                                    :disabled="field.disabled"
                                    ></v-switch>
                                    <v-text-field v-else-if="field.name == 'price'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="priceTarif"
                                    :name="field.name"
                                    :type="field.type"
                                    readonly
                                    
                                    >
                                    </v-text-field>
                                    
                                    <v-text-field v-else
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data_tv[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :type="field.type"
                                    :disabled="field.disabled"
                                    >
                                    </v-text-field>
                                
                                </v-col>
                                <v-col cols="12" class="py-2">
                                    <keep-alive>
                                        <AbonentTarifSumm ref="AbonentTarifSumm" :abonent_id="abonent.id" :form_data="form_data_tv"/>
                                    </keep-alive>
                                </v-col>
                            </template>
                            
                            
                        </v-col>
                        
                        <v-spacer />
                    
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit"
                :disabled="countChangeAbonentData < 2" 
                color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Зберегти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import AbonentTarifSumm from '@/components/AbonentInfo/AbonentTarifSumm.vue'
    import DetailTarifForFilters from '@/components/AbonentInfo/TabInfoCards/DetailTarifForFilters.vue'
    import { mapGetters } from "vuex"
	export default {
        components:{
            AbonentTarifSumm,
            DetailTarifForFilters
        },
        name: 'add-rates-modal',
        props: ["abonent", "service_tv", "service_inet", "checkDoubleTarif"],
		data: () => ({
            is_special_tarif_for_distributor: false,
            listFiltersTarif: [],
            showRatesTv: false,
            loadingSubmit:false,
            changeForm: false,
            item: {},
            abonentData: {},
            countChangeAbonentData: 0,
            defaultAbonentData: {
                add: {title: "Додати Послугу", method: "POST", readonly: false, message: "Послугу додано!"},
                change: {title: "Зміна Тарифу", method: "POST", readonly: true, message: "Послугу змінено!"}
            },
            dialog: false,
            valid: true,
            form_data: {},
            priceTarif: 0,
            form_data_tv:{
                date_tarif_change: "",
                tarif: "Новий",
                status: false,
                owner: "",
                novakom: "true",
            },
            fieldsTv: [
                //{title: "Дата Договору", name: "date_tarif_change",      rules: false,  type: "date",     disabled: true, value: "" },
                {title: "Нова Дата тарифу", name: "date_tarif_change",      rules: true,  type: "date",     disabled: false, value: "" },
                {title: "Тариф",         name: "tarif",     rules: false,  type: "select",   disabled: false, value: "", values: []},
                {title: "Сума абонплати ( грн. )",         name: "price",   rules: false,  type: "number",   disabled: true, value: "", },
                //{title: "Статус",        name: "status",    rules: false,  type: "switch",   disabled: false,  value: "" },
                //{title: "Квартиранти",   name: "owner",     rules: false,  type: "switch",   disabled: false, value: "" },
                //{title: "НоваКом",       name: "novakom",   rules: false,  type: "switch",   disabled: false, value: "" },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                rates: "filters/rates",
                rates_tv: "filters/rates_tv",
                rates_tv_special: "filters/rates_tv_special",
                distributors: "filters/operators", 
            }),
        },
        
        mounted(){
            this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.add))
        },
        watch:{
            form_data_tv:{
                handler () {
                    //console.log("form_data GHANGE!")
                    this.countChangeAbonentData += 1
                    //console.log(this.$router)
                    //this.countChangeAbonentData > 1 && this.$refs.AbonentTarifSumm.getSumm()
                    if((this.form_data_tv.tarif?.id || this.form_data_tv.tarif) != (this.service_tv.tarif?.id || this.service_tv.tarif)){
                        if(this.$refs.AbonentTarifSumm) this.$refs.AbonentTarifSumm.clearSumm()
                        if(this.$refs.AbonentTarifSumm) this.$refs.AbonentTarifSumm.getSumm()
                    }else if(this.$refs.AbonentTarifSumm) this.$refs.AbonentTarifSumm.clearSumm()
                },
                deep: true,
            },
            
            
        },
        methods: {
            show(type){
                
                this.priceTarif = 0
                this.is_special_tarif_for_distributor = false
                
                //console.log(order);
                this.clearModal()
                this.createValuesRates()
                if(type == "change"){
                    this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.change));
                    this.changeForm = true;
                    
                    this.service_tv.date_tarif_change = new Date().toDateInputValue() // додаємо в об'єкт сервісу ТБ нове поле "Нова дата тарифу"
                    this.form_data_tv = this.service_tv && JSON.parse(JSON.stringify(this.service_tv)) || JSON.parse(JSON.stringify(this.form_data_tv));
                    this.eventChangeTarifField()
                    
                    this.countChangeAbonentData = 0
                    
                }
                
                this.dialog = true;

                
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog(){
                this.dialog = false
                this.reset()
            },
            clearModal(){
                this.changeForm = false
                this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.add))
                this.showRatesTv = false
            },
            createValuesRates(){
                const distributor = this.distributors.find(x => x.id == this.service_tv?.operator)
                this.is_special_tarif_for_distributor = distributor?.is_special_tarifs
                if(distributor?.is_special_tarifs) this.fieldsTv.find(x => x.name == "tarif").values = this.rates_tv_special
                else this.fieldsTv.find(x => x.name == "tarif").values = this.rates_tv 
            },
            eventChangeTarifField(){
                //console.log("eventChangeTarifField")
                let tarifID = typeof(this.form_data_tv.tarif) == 'object' && this.form_data_tv.tarif.id || this.form_data_tv.tarif
                const tarif = this.rates.find(x => x.id == tarifID)
                if(!tarif) return
                this.priceTarif = Number(tarif.price)
            },
            apllyFiltersTarifs(){
                this.fieldsTv.find(x => x.name == "tarif").values = this.$refs.filtersTarifs[0].rates
            },
            sendForm() {

                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    /* Для Телебачення */
                    let data_send = {
                        date_tarif_change: this.form_data_tv.date_tarif_change,
                        tarif: typeof(this.form_data_tv.tarif) == 'object' && this.form_data_tv.tarif.id || this.form_data_tv.tarif,
                        service: 1
                    }
                    //console.log(data_send)
                    
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: this.abonentData.method,
                        url: `/api/service/change_tarif/${this.abonent.id}/`,
                        data: data_send,
                    })
                    .then(response => {
                        //console.log(response);
                        this.$emit('update-abonent');
                        this.$router.app.$snack.show(response.data);
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                }
                
            },
            
        }
    }
    
</script>
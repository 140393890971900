<template>
  <v-card id="card-connect-inet" class="mb-4" outlined elevation="0" :loading="loadingGetAbonentStatusConnect">
        <v-toolbar
        color="secondary lighten-1"
        flat dark
        dense>
          <v-toolbar-title ><h4 class="mr-6 accent--text">З'єднання</h4></v-toolbar-title>
          <v-spacer />
          <v-btn 
            :loading="loadingGetAbonentStatusConnect"
            color="accent"
            icon  
            title="Поновити данні з білінгу"
            @click="getAbonentStatusConnect"
          >
            <v-icon>autorenew</v-icon>
          </v-btn>
          <v-menu buttom offset-y >
          <template v-slot:activator="{ on }">
              <v-btn 
                v-on="on"
                class="" 
                color="accent"
                icon
                title="Опції"
              >
                <v-icon >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              
              
              <v-list-item @click="$parent.$parent.toTabConnection()">
                <v-icon class="pr-3">mdi-web</v-icon>
                <v-list-item-title>Підключення</v-list-item-title> 
              </v-list-item>
              <v-list-item :to="{name: 'find_ports', params: {search: `${this.abonent.street.street.slice(0, 7)} ${this.abonent.house} ${this.abonent.letter || ''}`}}" >
                <v-icon class="pr-3">mdi-layers-search</v-icon>
                <v-list-item-title>Пошук портів <span class="error--text">( тест )</span></v-list-item-title> 
              </v-list-item>
              <v-divider />
              <v-list-item @click="setMacAbonent()" v-if="isAvilableAndNoValidMac">
                <v-icon class="pr-3">mdi-swap-horizontal</v-icon>
                <v-list-item-title>Змінити MAC Абонента</v-list-item-title> 
              </v-list-item>
              <v-list-item @click="$refs.changeBinding.show()" >
                <v-icon class="pr-3">mdi-pencil</v-icon>
                <v-list-item-title>Редагувати прив'язку</v-list-item-title> 
              </v-list-item>
              <v-list-item v-if="connectData && connectData.caller_id_binding" @click="$refs.confirmResetBinding.show()" >
                <v-icon class="pr-3">mdi-backup-restore</v-icon>
                <v-list-item-title>Скинути прив'язку</v-list-item-title> 
              </v-list-item>
              
            </v-list>
          </v-menu>
          <v-btn
            v-if="service_inet_billing && $vuetify.breakpoint.smAndDown"
            color="accent"
            icon
            @click="showConnectInet = !showConnectInet"
          >
            <v-icon>{{ showConnectInet && 'mdi-chevron-up' || 'mdi-chevron-down'}}</v-icon>
          </v-btn>
        </v-toolbar>
        
          <v-alert
            v-if="connectDataError"
            rounded="0"
            class="my-0 mb-1"
            color="red  lighten-1"
            dense
            type="error"
          >
            {{ connectDataError }}
          </v-alert>

          <template v-if="connectData.errors">
            <v-alert v-for="(err, index) in connectData.errors" :key="index"
              rounded="0"
              class="my-0 mb-1"
              dense
              :type="connectErrorMedia[err.error].type"
              :icon="connectErrorMedia[err.error].icon"
            >
              {{ err.message }}
            </v-alert>
          </template>
        <!--
          <v-alert
            v-if="connectData.not_auth"
            rounded="0"
            class="my-0 mb-1"
            dense
            type="warning"
          >
            Абонент отримав IP адресу, але неавторизований
          </v-alert>
          <v-alert
            v-if="connectData.real_ip && connectData.ip_abonent && !actualRealIp"
            rounded="0"
            class="my-0"
            dense
            type="warning"
          >
            Абонент не отримав статичну IP адресу ({{ connectData.real_ip }})
          </v-alert>
        -->
          <v-list dense v-if="abonent && showConnectInet" class="pt-3">
            
            <template v-for="(item, index) in connectCardItems">
              
              <v-list-item
                v-if="item.name != 'caller_id_binding'"
                dense
                :key="index + 1001"
                
              >
                
                <v-list-item-content class="align-self-start pt-0">
                  <v-list-item-subtitle v-text="item.title"></v-list-item-subtitle>
                
                  <template v-if="item.name == 'online'">
                    <span class="">
                      <v-chip small text-color="white" class="mr-2 mb-1" :color="connectData.online ? 'light-blue ': 'grey' " >{{ connectData.online && "Online" || "Offline" }}</v-chip>
                      
                      <v-chip class="mr-2 mb-1" small outlined title="Тип підключення" v-if="connectData.caller_id_now && connectData.caller_id_now.type_connect">{{ connectData.caller_id_now && connectData.caller_id_now.type_connect }} {{ connectData.caller_id_now && connectData.caller_id_now.onu_number && ' (xPON)' }}</v-chip>
                      <v-chip small class="mr-2 mb-1" :title="`Швидкість: ${connectData && connectData.speed } Mb/s`" v-if="connectData && connectData.speed" color="primary" outlined >{{ connectData && connectData.speed }}Mb/s</v-chip>
                      
                      
                      <v-chip small class="mr-2 mb-1" outlined color="grey" v-if="connectData.hostname" :title="`Модель пристрою:\n${connectData.hostname}\n\nDHCP Client:\n${connectData.dhcp_client}`">{{ connectData.hostname }}</v-chip>
                    
                    </span>
                    
                  </template>
                  <template v-else-if="item.name == 'ip_abonent'">
                    <span>
                      {{ connectData.ip_abonent }}
                      <v-chip x-small class="ml-1 mb-1" :title="`Зарезервована IP:\n${realIP}`" v-if="realIP" :color="realIP != connectData.ip_abonent ? 'error':'blue'" outlined >Real IP</v-chip>
                    </span>
                  </template>

                  <template v-else-if="item.name == 'caller_id_now'" >
                    <template v-if="connectData && connectData.caller_id_now">
                      <template v-if="connectData.caller_id_now.type_connect == 'IPoE'">
                        <span>{{ `${connectData.caller_id_now.mac_switch} / ${connectData.caller_id_now.vlan} / ${connectData.caller_id_now.port} /`}}</span>
                        <span>{{ connectData.caller_id_now.mac_abonent}}</span>
                      </template>
                      <template v-else-if="connectData.caller_id_now.type_connect == 'PPTP'">
                        {{ connectData.caller_id_now.ip}}
                      </template>
                      <template v-else-if="connectData.caller_id_now.type_connect == 'PPPoE'">
                        {{ `${connectData.caller_id_now.vlan} / ${connectData.caller_id_now.mac_abonent}`}}
                      </template>
                    </template>
                  </template>

                  <template v-else>
                    {{ connectData[item.name] || item.value }}
                  </template>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-else-if="item.name == 'caller_id_binding'"
                dense
                :key="index + 1001"
              >
                <v-list-item-content class="align-self-start pt-0">
                  <v-list-item-subtitle v-text="item.title"></v-list-item-subtitle>

                  <template v-if="connectData.caller_id_binding">
                    <template v-if="connectData.caller_id_now">
                        <small v-if="actualCallerID" class="grey--text">
                          Дані прив'язки співпадають з Caller ID
                        </small>
                        <template v-else>
                          <span><span :class="isValidMacSwitsh ? '':'deep-orange--text'">{{connectData.caller_id_binding.mac_switch}}</span> / <span :class="isValidVlan ? '':'deep-orange--text'">{{connectData.caller_id_binding.vlan }}</span> / <span :class="isValidPort ? '':'deep-orange--text'">{{connectData.caller_id_binding.port}}</span> /</span>
                          <span :class="isValidMacAbonent ? '':'deep-orange--text'">{{ connectData.caller_id_binding.mac_abonent}}</span>
                        </template>
                      </template>
                    <template v-else>
                        <span>{{connectData.caller_id_binding.mac_switch}} / {{connectData.caller_id_binding.vlan }} / {{connectData.caller_id_binding.port}} /</span>
                        <span>{{ connectData.caller_id_binding.mac_abonent}}</span>
                    </template>
                  </template>

                  <template v-else>
                    <span class="error--text">Прив'язка відсутня</span>
                  </template>
                </v-list-item-content>
            </v-list-item>
            </template>
            <template>
              <v-divider v-if="dataSwitch || dataSwitches" class=""/>
              <template v-if="dataSwitch">
                <v-subheader class="ml-2">КОМУТАТОР: </v-subheader>
                <v-list-item v-for="(swt, index) in dataSwitch" :key="index + 'mac'" class="py-0">
                  <v-list-item-content class="align-self-start pb-2 pt-0">
                    <v-list-item-subtitle>{{ swt.model.name }} ( {{ swt.entrance }} п-д ) <v-chip v-if="swt.status || swt.status == false" x-small class="py-0 white--text" :color="swt.status ? 'blue': 'red'" style="vertical-align: bottom;">{{ swt.status ? 'online': 'offline' }}</v-chip></v-list-item-subtitle>
                    <span>{{ swt.mac }} / <span :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : 'blue--text text--darken-2'">{{ swt.ip }}</span><v-icon class="ml-1" style="vertical-align: top;" small v-if="currentSwitch(swt.mac)" title="Актуальний" color="primary lighten-1">mdi-connection</v-icon></span>
                    <v-list-item-subtitle> {{ chechAddressAbSw(swt) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="swt.mac" class="py-0 my-0">
                    <v-btn :loading="loadingCheckOnline" small icon @click="checkOnlineSwitch(swt)">
                      <v-icon>autorenew</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-if="dataSwitches && dataSwitches.length > 0 && checkOnceSwitch()">
                <v-subheader class="ml-2">УСІ КОМУТАТОРИ: </v-subheader>
                <v-list-item v-for="(swt, index) in dataSwitches" :key="index" class="py-0">
                  <v-list-item-content class="align-self-start pb-2 pt-0">
                    <v-list-item-subtitle>{{ swt.model.name }} ( {{ swt.entrance }} п-д ) </v-list-item-subtitle>
                    <span>{{ swt.mac }} / <span :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : 'blue--text text--darken-2'">{{ swt.ip }}</span><v-icon class="ml-1" style="vertical-align: top;" small v-if="currentSwitch(swt.mac)" title="Актуальний" color="primary lighten-1">mdi-connection</v-icon></span>
                  </v-list-item-content>
                  
                </v-list-item>
              </template>
              
            </template>
            

            
          </v-list>
           
          <v-card-actions v-if="abonent && showConnectInet">
            
            <!--<v-btn v-if="abonent && abonent.operator_group && abonent.operator_group.id == 2"
                outlined  class="ml-3" small target="_blank" :href="`https://t.me/share/url?url=${encodeURIComponent('id ' + service_inet_billing.contract)}`">
                Switch
            </v-btn>-->
             <v-btn
                outlined  class="ml-2" small 
                :loading="loadingBtnSwitch"
                @click="getdataSwitch()">
                Switch
            </v-btn>
            <v-spacer />
            
            <v-menu  offset-y open-on-hover offset-overflow>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="d-none mr-2"
                  color="primary"
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  
                >
                Stat
                <v-icon small right>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item target="_blank"
                :href="`https://stat2.cabletv.dp.ua/?userSearchID=${service_inet_billing && service_inet_billing.user_id}`"
                >
                  <v-list-item-title>Stat 2</v-list-item-title>
                </v-list-item>
                <v-list-item target="_blank"
                :href="`https://stat3.cabletv.dp.ua/?userSearchID=${service_inet_billing && service_inet_billing.user_id}`"
                >
                  <v-list-item-title>Stat 3</v-list-item-title>
                </v-list-item>
                <v-list-item target="_blank"
                :href="`https://stat4.cabletv.dp.ua/?userSearchID=${service_inet_billing && service_inet_billing.user_id}`"
                >
                  <v-list-item-title>Stat 4</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
              
            
            
          </v-card-actions>
          <v-overlay z-index=4 :value="loadingGetAbonentStatusConnect" absolute >
            <v-progress-circular
              indeterminate
              size="48"
            ></v-progress-circular>
          </v-overlay>
          <ChangeBindingModal ref="changeBinding" :user_pcode="abonent.user_pcode" v-on:update-binding="getAbonentStatusConnect()"/>
          <ModalConfirmResetBinding ref="confirmResetBinding" :user_pcode="abonent.user_pcode" v-on:update-binding="getAbonentStatusConnect()"/>
      </v-card> 
</template>

<script>
import axios from "axios"
import streetOldKtk from '@/components/AbonentInfo/TabInfoCards/streetOldKtk.js'
import ChangeBindingModal from '@/components/AbonentInfo/ChangeBindingModal.vue'
import ModalConfirmResetBinding from '@/components/AbonentInfo/ModalConfirmResetBinding.vue'

export default {
  props: ["user", "abonent", "service_inet_billing", "connectData", "connectDataError", "loadingGetAbonentStatusConnect", "realIP"],
  components: {
    ChangeBindingModal,
    ModalConfirmResetBinding
  },
  data: () => ({
    streetOldKtk: streetOldKtk,
    dataSwitch: null,
    dataSwitches: null,
    loadingCheckOnline: false,
    loadingBtnSwitch: false,
    showConnectInet: true,
    showPassword: false,
    connectCardItems: [],
    connectCardItemsOnline: [
      {title: "",       name: "online", type: "text"},
      {title: 'Вхід у мережу:',     name: "time_connect" },
      {title: "Остання активність:",name: "time_last_active", type: "text",},
      {title: "Caller ID:",         name: "caller_id_now", type: "text"},
      {title: "Прив'язка абонента:",name: "caller_id_binding", type: "text"},
      {title: "Отримана IP адреса:",      name: "ip_abonent", type: "text"},
    ],
    connectCardItemsOffline: [
      {title: "",       name: "online", type: "text"},
      {title: "Остання активність:",name: "time_last_active", type: "text",},
      {title: "Останній Caller ID:",         name: "caller_id_now", type: "text"},
      {title: "Прив'язка абонента:", name: "caller_id_binding", type: "text"},
      
    ],
    connectCardItemsTryConnect: [
      {title: "",       name: "online", type: "text"},
      {title: 'Спроба входу в мережу:',     name: "time_connect" },
      {title: "Остання активність:",name: "time_last_active", type: "text",},
      {title: "Caller ID:",         name: "caller_id_now", type: "text"},
      {title: "Прив'язка абонента:",name: "caller_id_binding", type: "text"},
      {title: "Отримана IP адреса:",      name: "ip_abonent", type: "text"},
    ],
    connectErrorMedia: {
      1: { icon : 'mdi-alert-circle-outline', type: 'warning'},
      2: { icon : 'mdi-ip-outline', type: 'warning'},
      3: { icon : 'mdi-speedometer', type: 'warning'},
      4: { icon : 'mdi-exclamation-thick', type: 'warning'},
      5: { icon : 'mdi-alert-circle-outline', type: 'info'}
      //4: 'mdi-connection'
    }
}),
computed: {
  actualCallerID (){
    return JSON.stringify(this.connectData.caller_id_binding) == JSON.stringify(this.connectData.caller_id_now)
  },
  actualRealIp(){
    return JSON.stringify(this.connectData.ip_abonent) == JSON.stringify(this.connectData.real_ip)
  },
  isAvilableAndNoValidMac(){
     return this.connectData?.caller_id_now?.mac_abonent && this.connectData?.caller_id_binding?.mac_abonent && (JSON.stringify(this.connectData?.caller_id_now?.mac_abonent) != JSON.stringify(this.connectData?.caller_id_binding?.mac_abonent))
  },

  isValidMacSwitsh(){
    return JSON.stringify(this.connectData?.caller_id_binding?.mac_switch) == JSON.stringify(this.connectData?.caller_id_now?.mac_switch)
  },
  isValidVlan(){
    return JSON.stringify(this.connectData?.caller_id_binding?.vlan) == JSON.stringify(this.connectData?.caller_id_now?.vlan)
  },
  isValidPort(){
    return JSON.stringify(this.connectData?.caller_id_binding?.port) == JSON.stringify(this.connectData?.caller_id_now?.port)
  },
  isValidMacAbonent(){
    return JSON.stringify(this.connectData?.caller_id_binding?.mac_abonent) == JSON.stringify(this.connectData?.caller_id_now?.mac_abonent)
  },
   
},
watch:{
  connectData(){
    if (this.connectData.online) this.connectCardItems = this.connectCardItemsOnline
    else if(this.connectData.ip_abonent) this.connectCardItems = this.connectCardItemsTryConnect
    else this.connectCardItems = this.connectCardItemsOffline
  }
},
mounted(){
  //console.log('mounted CardConnectInet')
  //this.getAbonentStatusConnect()
},
activated(){

  //console.log('activate CardConnectInet')
  //this.getAbonentStatusConnect()
},
deactivated(){
  this.dataSwitch = null
  this.dataSwitches = null
},

methods:{
  checkOnceSwitch(){
    /* Перевіряємо чи на будинку тільки один комутатор. Якщо ні - повертаємо список */
    
    return this.dataSwitches.length > 1 || !this.dataSwitch || this.dataSwitch[0].mac != this.dataSwitches[0].mac
  },
  chechAddressAbSw(swt){
    /* Перевіряємо чи адреса комутатора співпадає з адресою абонента. Якщо ні - повертаємо адресу */
    if( this.abonent.street.id != swt.street_id || this.abonent.house != swt.house || this.abonent.letter.toLowerCase() != swt.letter.toLowerCase()) return `${swt.street} ${swt.house}${swt.letter}`
    else return ''
  },
  currentSwitch(mac){
    let switchMac = this.connectData?.caller_id_now?.mac_switch  || this.connectData?.caller_id_binding?.mac_switch 
    return switchMac && switchMac == mac
  },
  getAbonentStatusConnect(){
    this.$emit("get-status-connect")
  },
  checkOnlineSwitch(swt){
    /** Запит на перевірку статусу комутатора чи онлайн/офлайн */
    this.loadingCheckOnline = true
    axios({
      method: 'get',
      url:`${this.$router.app.devUrlServiceSwitches}/api_network/check_switch/`,
      params: {mac: swt.mac}})
      .then(response => {
        if(response.data){
          console.log(response.data)
          this.dataSwitch.find(x => x.mac == swt.mac).status = response.data[0].status
          //this.dataSwitch = response.data
        }
      })
      .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
      .finally(() => (this.loadingCheckOnline = false))
  },
  setMacAbonent(){
    this.$parent.loading = true
    axios({
      method: "post",
      url: this.$router.app.devUrlBillingAPI,
      data: {req_cmd: "changeUserMAC", pcode: this.abonent?.user_pcode, arg: this.connectData.caller_id_now.mac_abonent }
    })
      .then(response => {
        //console.log(response)
        if(response.data.resp_status == "OK"){
          this.$router.app.$snack.show("MAC адресу змінено")
        }else if(response.data.resp_status == "REJECT"){
            this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg, 'deep-orange')
        }else{
            this.$router.app.$snack.show("Помилка зміни даних прив'язки", 'deep-orange')
        }
      })
      .catch(err => {
        //console.log(err);
        this.$router.app.$sheet.show("Помилка!", err);
      })
      .finally(() => (this.$parent.loading = false, this.getAbonentStatusConnect()))
  },
  getdataSwitch(){
    this.loadingBtnSwitch = true
    let switchMac = this.connectData?.caller_id_now?.mac_switch  || this.connectData?.caller_id_binding?.mac_switch
    this.getdataSwitches()
    if(!switchMac) return
    axios({
      method: 'get',
      url:`${this.$router.app.devUrlServiceSwitches}/api_network/search_switch/`,//params: {street: this.streetOldKtk[this.abonent.street.id], house: this.abonent.house, letter: this.abonent.letter}})
      params: {mac: switchMac}})
      .then(response => {
        if(response.data){
          this.dataSwitch = response.data
          this.dataSwitch.forEach(el => {el.mac && this.checkOnlineSwitch(el)})
        }
      })
      .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
  },
  getdataSwitches(){
    //this.loadingBtnSwitch = true
    axios({
      method: 'get',
      url:`${this.$router.app.devUrlServiceSwitches}/api_network/search_switch/`,//params: {street: this.streetOldKtk[this.abonent.street.id], house: this.abonent.house, letter: this.abonent.letter}})
      params: {street_id: this.abonent.street.id, house: this.abonent.house, letter: this.abonent.letter}})
      .then(response => {
        if(response.data){
          this.dataSwitches = response.data
        }
      })
      .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
      .finally(() => (this.loadingBtnSwitch = false))
  }
},

}
</script>
<style scoped>

</style>

<template>
    <v-card class="mb-4" outlined elevation="0">
      
          <v-toolbar
          color="secondary lighten-1"
          flat dark
          dense>
            <v-toolbar-title ><h3 class="mr-6 accent--text">Картка</h3></v-toolbar-title>
            <v-spacer />
            
            <v-btn
              v-if="user && user.user_type != 2"
              title="Більше інформації"
              @click="viewMore = !viewMore"
              icon
              :color="viewMore ? 'accent': 'grey'"
            >
              <v-icon>mdi-unfold-more-horizontal</v-icon>
            </v-btn>

            <v-btn
              v-if="user && user.user_type != 2"
              title="Створити нагадування"
              @click="$parent.$refs.addNotification.show('add', abonent)"
              icon
              color="accent"
            >
              <v-icon>mdi-message-plus-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="user && user.user_type != 2"
              @click="$parent.$refs.addAbonent.show('change', abonent)"
              icon
              color="accent"
              title="Змінити дані абонента"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              icon
              color="accent"
              @click="showCardAbonent = !showCardAbonent"
            >
              <v-icon>{{ showCardAbonent && 'mdi-chevron-up' || 'mdi-chevron-down'}}</v-icon>
            </v-btn>
          </v-toolbar>

          <v-alert
              v-if="abonent && abonent.delete"
              rounded="0"
              class="my-0 mb-1"
              color="red  lighten-1"
              dense
              type="warning"
              icon="mdi-account-off-outline"
            >
              Видалений абонент
            </v-alert>

            <v-list dense v-if="abonent && showCardAbonent" class="">
              <template v-if="viewMore">
                <v-list-item>
                  <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
                    <v-list-item-title >PCODE:</v-list-item-title>
                  </v-list-item-content> 
                  <v-list-item-content>
                      {{ abonent.user_pcode }}
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>

              <template v-if="abonent.is_organization">
                <v-list-item>
                  <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
                    <v-list-item-title >Організація:</v-list-item-title>
                  </v-list-item-content> 
                  <v-list-item-content>
                    <div class="d-flex justify-space-between align-center">
                      <span class="deep-orange--text">{{ abonent.organization.name }}</span>
                      <span>
                          <v-btn
                          color="primary"
                          class="ml-2"
                          title="Редагувати дані організації"
                          small
                          icon
                          @click="$parent.$refs.addOrganizationModal.show('change')"
                          >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </span>
                    </div>
                    
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
              
              <template v-for="(item, index) in abonentCardItems">
                <v-divider
                  v-if="index != 0"
                  :key="index + 201"
                ></v-divider>
                <v-list-item
                  :key="index + 101"
                  :three-line="item.name == 'user_phones' && abonent && abonent.user_phones.find(x => x.memo && x.memo.length > 28) && true || false"
                >
                  
                  <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content> 

                  <v-list-item-content>

                    <template v-if="item.name == 'user_phones'">
                      <div class="d-flex justify-space-between align-center">
                        <div v-if="abonent && abonent.user_phones.length != 0" style="display: grid;">
                          <span v-for="phone in abonent.user_phones" :key="phone.id" class="mb-1">
                            <!--{{ abonent && abonent[item.name].length != 0  && abonent[item.name].find(x => x.main).phone || ''  }}-->
                            <v-list-item-subtitle v-if="phone.memo" >{{ phone.memo }}:</v-list-item-subtitle>
                           
                            <!-- Меню викликів -->
                            <v-menu buttom offset-y>
                              <template v-slot:activator="{ on }">
                                <span v-on="on"  class="phone-field-number"><a  style="text-decoration: none;">{{ phone.phone }}</a> <v-icon small title="Основний" v-if="phone.main" style="vertical-align: inherit;" color="accent">mdi-checkbox-marked-circle</v-icon></span>
                              </template>
                              <v-list dense :class="{'py-0': !is_user_call_centre}">
                                <v-list-item :href="`tel:${phone.phone}`">
                                  <v-icon class="pr-3">mdi-phone-outgoing</v-icon>
                                  <v-list-item-title>Виклик</v-list-item-title> 
                                </v-list-item>
                                <v-list-item v-if="is_user_call_centre" @click="callForCallCentre(phone.phone)">
                                  <v-icon class="pr-3">mdi-cellphone</v-icon>
                                  <v-list-item-title>Виклик Колл-Центр</v-list-item-title> 
                                </v-list-item>
                                <v-list-item @click="$parent.$refs.addMessage.show(phone.phone)">
                                  <v-icon class="pr-3">mdi-message-outline</v-icon>
                                  <v-list-item-title>Повідомлення</v-list-item-title> 
                                </v-list-item>
                                
                              </v-list>
                            </v-menu>
                            <!------------------->
                          
                          </span>
                        </div>
                        <div v-else></div>
                        <span>
                            <v-btn
                            color="primary"
                            class="ml-2"
                            :title="abonent && abonent.user_phones.length != 0 ? 'Змінити телефон' : 'Додати телефон'"
                            small
                            icon
                            @click="$parent.$refs.changePhone.show()"
                            >
                              <v-icon v-if="abonent && abonent.user_phones.length != 0">mdi-pencil</v-icon>
                              <v-icon v-else>mdi-plus</v-icon>
                          </v-btn>
                        </span>
                      </div>
                    </template>

                    <template v-else-if="item.name == 'address'">
                      {{ abonent && `${abonent.street.street_view} ${abonent.house}${abonent.letter || ''}${abonent.corpus > 0 && ' (к.'+abonent.corpus+')' || ''} ${abonent.flat}${abonent.tenand > 0 && ' (н.'+abonent.tenand+')' || ''}` || ''}} 
                    </template>
                    
                    <template v-else-if="item.name == 'operator'">
                      {{ abonent && abonent.operator_group.name}} 
                    </template>

                    <template v-else-if="item.name == 'password'">
                      <ViewPasswordBilling v-if="service_inet" :abonent_pcode="abonent.user_pcode" :modalChangePass="$parent.$refs.changePassword"/>
                      <ViewPasswordDefault v-else :password="abonent.password" :modalChangePass="$parent.$refs.changePassword"/> 
                    </template>
                    

                    <template v-else-if="item.name == 'pasport'">
                      {{ abonent && abonent.serial_number_pasport }}
                      <v-list-item-subtitle>{{ abonent && abonent.date_pasport }}</v-list-item-subtitle>
                    </template>

                    <template v-else>
                      {{ abonent && abonent[item.name] || item.value }}
                    </template>
                    <!--<v-list-item-title v-if="item.type == 'text'" v-text="abonent && abonent[item.name] || item.value"></v-list-item-title>
                    <v-list-item-subtitle v-else></v-list-item-subtitle>-->
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="abonent && abonent.tags && abonent.tags.length > 0">
                <v-divider />
                <v-list-item >
                  <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
                      <v-list-item-title>Теги:</v-list-item-title>
                    </v-list-item-content> 
                    <v-list-item-content>
                      <span>
                      <template v-for="tag in abonent.tags">
                      <v-icon :key="tag.id" :title="tag.name" :color="tag.color" class="mr-1">
                          mdi-tag
                        </v-icon>
                      </template>
                      </span>
                    </v-list-item-content> 
                </v-list-item>
              </template>
              
            </v-list>
            <v-card-actions v-if="abonentCardItemsChips.find(item => abonent && abonent[item.name]) && showCardAbonent" class="pt-0">
              <template v-for="(item, index) in abonentCardItemsChips">
                <v-spacer :key="'sp' + index" v-if="item.name == 'district'" />
                <v-chip
                  v-if="abonent && abonent[item.name]"
                  :key="index"
                  :color="item.color"
                  class="ma-2"
                  :outlined="item.outlined"
                  small
                  pill
                  :dark="item.dark"
                >
                  <v-icon left small v-if="item.icon">{{ item.icon }}</v-icon>
                  <span v-if="item.name == 'district'" title="Дільниця" >
                    {{ districts.find(x => x.id == abonent.district) && districts.find(x => x.id == abonent.district).name || 'не знайдено' }} 
                  </span>
                  <span v-else>{{ item.title }}</span>
                </v-chip>
              </template>
            </v-card-actions>
        </v-card> 
        
</template>

<script>
import axios from "axios"
import ViewPasswordBilling from '@/components/AbonentInfo/TabInfoCards/ViewPasswordBilling.vue'
import ViewPasswordDefault from '@/components/AbonentInfo/TabInfoCards/ViewPasswordDefault.vue'
export default {
  props: ["user", "abonent", "service_inet", "districts"],
  components: {
    ViewPasswordDefault,
    ViewPasswordBilling
  },
  data: () => ({
    viewMore: false,
    showCardAbonent: true,
    abonentCardItems: [
      {title: "П.І.Б:",             name: "full_name", type: "text"},
      {title: "Адреса:",            name: "address", type: "text"},
      //{title: "Організація:",       name: "organization", type: "text"},
      //{title: 'Особовий рахунок:',  name: "id" },
      //{title: "Корпус:",          name: "corpus", type: "text",},
      //{title: "Наймач:",          name: "tenand", type: "text",},
      {title: "Телефон:",           name: "user_phones", type: "text", three_line: true},
      {title: "Оператор:",          name: "operator", type: "text"},
      {title: "Логін:",             name: "username", type: "text"},
      {title: "Пароль:",            name: "password", type: "text"},
      //{title: "Паспортні данні:", name: "pasport", type: "sub"}
      //{title: "", name: "", type: ""}
    ],
    abonentCardItemsChips: [
      {title: "Квартирант",    name: "not_owner", color: "primary", outlined: true},
      //{title: "Юр.особа",      name: "is_organization", color: "deep-orange", outlined: true},
      {title: "Дільниця: ", icon: 'mdi-map-marker-outline',      name: "district", color: "primary lighten-1" , dark: true},
      
    ],
  }),
  computed: {
    is_user_call_centre(){
      return this.user.call_centre || false
    }
  },
  mounted(){
    //
  },
  methods:{
    callForCallCentre(phone_number){
      axios({
              method: "post",
              url: `${this.$router.app.devUrlServiceCalls}/api_calls/make_call`,
              data: {state_owner: this.is_user_call_centre, phone_number: phone_number}
          })
            .then(response => {
            if(response){
              this.$router.app.$snack.show("Дзвінок передано!")
              
            }else{
                this.$router.app.$snack.show("Помилка", 'deep-orange')
            }
            })
            .catch(err => {
            //console.log(err);
                this.$router.app.$sheet.show("Помилка!", err);
            })
    }
  }
}
</script>

<style scoped>
.phone-field-number:hover{
  filter: brightness(130%)
}
</style>
<template>
        
        <v-dialog v-model="dialog" persistent :max-width="modalData.type == 'split' ? 800 : 500">
            
            <v-card>
            <v-card-title class="">
                <span class="headline">{{ modalData.title }} </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="">
                <v-form 
                ref="formDouble"
                v-model="valid"
                lazy-validation>
                <v-row justify="center" v-if="modalData.type == 'split'">
                    <v-col cols="12" md="6" class="px-6 pb-5"> 
                        <v-text-field 
                            dense
                            outlined
                            hide-details="auto"
                            label="Дата розʼєднання послуг"
                            v-model="form_data_tv.date_tarif_change"
                            name="date_tarif_change"
                            :rules="textRules || notRules"
                            type="date"
                            >
                            </v-text-field>
                    </v-col>
                </v-row>
                <v-row >
                        
                            
                      
                        <v-col cols="12" :md="modalData.type == 'split' ? 6 : 12">   
                            <div class="mb-4 text-center">
                                <h4 >{{ modalData.titleForm }}</h4>
                            </div>
                            
                            <template>
                                <v-col v-for="field in fieldsTv" :key="field.name" cols="12" class="py-2">
                                    <v-select v-if="field.type == 'select'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data_tv[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :items="field.values"
                                    item-text="name"
                                    item-value="id"
                                    :disabled="field.disabled"
                                    @change="eventChangeTarifField()"
                                    >
                                    <template v-if="dialog" v-slot:prepend-item>
                                        <v-subheader style="height: 30px;">Фільтри:</v-subheader>

                                        <FiltersTarifs ref="filtersTarifs" v-on:create-values-rates="createValuesRates()" v-on:aplly-filters-tarifs="apllyFiltersTarifs()" :is_special_tarif_for_distributor="is_special_tarif_for_distributor" :service="modalData.type == 'split' ? 'tv' : 'double'"/>
                                        
                                        <v-divider />
                                    </template>
                                    <template v-if="field.name == 'tarif'" v-slot:item="{ item }">
                                        <div style="width: 100%;" class="d-flex justify-space-between">
                                            <span class="v-list-item__title">{{ item.name }}</span>
                                            <DetailTarifForFilters :tarif="item" />
                                        </div>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-row justify="center" class="mt-2 mb-2" >
                                            <small class="error--text">Тарифи не знайдено</small>
                                        </v-row>
                                    </template>
                                    </v-select>

                                    <v-switch v-else-if="field.type == 'switch'"
                                    dense
                                    hide-details
                                    :label="field.title"
                                    v-model="form_data_tv[field.name]"
                                    :disabled="field.disabled"
                                    ></v-switch>
                                    <v-text-field v-else-if="field.name == 'price'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="priceTarif"
                                    :name="field.name"
                                    :type="field.type"
                                    readonly
                                    
                                    >
                                    </v-text-field>
                                    
                                    <v-text-field v-else
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data_tv[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :type="field.type"
                                    :disabled="field.disabled"
                                    >
                                    </v-text-field>
                                
                                </v-col>
                                <v-col cols="12" class="py-2">
                                    <keep-alive>
                                        <AbonentTarifSumm ref="AbonentTarifSumm" :abonent_id="abonent && abonent.id" :form_data="form_data_tv"/>
                                    </keep-alive>
                                </v-col>
                            </template>
                            
                            
                        </v-col>
                        <template v-if="modalData.type == 'split'">
                            <FieldsChangeTarif ref="fieldInet"  month="now" title="ІНТЕРНЕТ" :tarif="{id:38, fee: 0, discount: 0}" :operator_id="service_inet_billing && service_inet_billing.operator" v-on:change-tarif-data="isChangeInetTarif = true" />
                        </template>
                        
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit"
                color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Зберегти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import AbonentTarifSumm from '@/components/AbonentInfo/AbonentTarifSumm.vue'
    import FieldsChangeTarif from '@/components/AbonentInfo/FieldsChangeTarif'
    import DetailTarifForFilters from '@/components/AbonentInfo/TabInfoCards/DetailTarifForFilters.vue'
    import { mapGetters } from "vuex"
	export default {
        components:{
            AbonentTarifSumm,
            FieldsChangeTarif,
            DetailTarifForFilters
        },
        name: 'add-rates-double-modal',
        props: ["abonent", "service_tv", "service_inet_billing", "checkDoubleTarif"],
		data: () => ({
            is_special_tarif_for_distributor: false,
            addRates: [],
            loadingSubmit:false,
            item: {},
            modalData: {},
            isChangeInetTarif: false,
            countChangeAbonentData: 0,
            defaultModalData: {
                change: {title: "Зміна Тарифу ПП",      titleForm: "ТБ + ІНТЕРНЕТ", method: "POST", readonly: false, message: "Тариф змінено!",     type: "change"},
                join:   {title: "Обʼєднання послуг", titleForm: "ТБ + ІНТЕРНЕТ", method: "POST", readonly: false, message: "Послуги обʼєднано!", type: "join"},
                split:  {title: "Розділення послуг", titleForm: "ТЕЛЕБАЧЕННЯ",   method: "POST", readonly: false, message: "Послуги розділено!", type: "split"}
            },
            dialog: false,
            valid: true,
            form_data: {},
            priceTarif: 0,
            default_form_data_tv:{
                date_tarif_change: "",
                tarif: 1,
                price: 0
            },
            form_data_tv:{},
            defaultFieldsTv: [
                //{title: "Дата Договору", name: "date_tarif_change",      rules: false,  type: "date",     disabled: true, value: "" },
                {title: "Нова Дата тарифу", name: "date_tarif_change",      rules: true,  type: "date",     disabled: false, value: "" },
                {title: "Тариф ПП",         name: "tarif",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Сума абонплати ( грн. )",         name: "price",   rules: false,  type: "number",   disabled: true, value: "", },
            ],
            defaultFieldsTvSplit: [
                {title: "Тариф ТБ",         name: "tarif",     rules: false,  type: "select",   disabled: false, value: "", values: []},
                {title: "Сума абонплати ( грн. )",         name: "price",   rules: false,  type: "number",   disabled: true, value: "", },
            ],
            fieldsTv: [],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                rates: "filters/rates",
                rates_tv: "filters/rates_tv",
                rates_tv_special: "filters/rates_tv_special",
                rates_double: "filters/rates_double",
                rates_double_special: "filters/rates_double_special",
                distributors: "filters/operators", 
            }),
        },
        
        mounted(){
            //this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.change))
        },
        watch:{
            form_data_tv:{
                handler () {
                    this.countChangeAbonentData += 1
                    if((this.form_data_tv.tarif?.id || this.form_data_tv.tarif) != (this.service_tv.tarif?.id || this.service_tv.tarif)){
                        if(this.$refs.AbonentTarifSumm) this.$refs.AbonentTarifSumm.clearSumm()
                        if(this.$refs.AbonentTarifSumm) this.$refs.AbonentTarifSumm.getSumm()
                    }else if(this.$refs.AbonentTarifSumm) this.$refs.AbonentTarifSumm.clearSumm()
                },
                deep: true,
            },
            
            
        },
        methods: {
            show(type){
                this.isChangeInetTarif = false
                this.countChangeAbonentData = 0

                this.modalData = JSON.parse(JSON.stringify(this.defaultModalData[type]))
                this.priceTarif = 0
                this.is_special_tarif_for_distributor = false
                
                
                //console.log(order);
                
                
                

                if(type == "change"){
                    this.fieldsTv = JSON.parse(JSON.stringify(this.defaultFieldsTv))
                    this.form_data_tv = this.service_tv && JSON.parse(JSON.stringify(this.service_tv)) || JSON.parse(JSON.stringify(this.default_form_data_tv));
                    this.eventChangeTarifField()
                }else if(type == "join"){
                    this.fieldsTv = JSON.parse(JSON.stringify(this.defaultFieldsTv))
                    this.form_data_tv = JSON.parse(JSON.stringify(this.default_form_data_tv))
                }else if(type == "split"){
                    this.fieldsTv = JSON.parse(JSON.stringify(this.defaultFieldsTvSplit))
                    this.form_data_tv = JSON.parse(JSON.stringify(this.default_form_data_tv))
                    this.$refs.fieldInet && this.$refs.fieldInet.show({id:38, fee: 0, discount: 0})
                }
                this.form_data_tv.date_tarif_change = new Date().toDateInputValue() // додаємо в об'єкт сервісу ТБ нове поле "Нова дата тарифу"
                this.createValuesRates()
                
                
                this.dialog = true

                
            },
            validate () {
                if (this.$refs.formDouble.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.formDouble.reset()
            },
            closeDialog(){
                this.dialog = false
                this.reset() 
                
            },
            clearModal(){
                //this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.change))
            },
            createValuesRates(){
                console.log("createValuesRates run func. operator: ", this.service_tv?.operator)
                const distributor = this.distributors.find(x => x.id == this.service_tv?.operator)
                this.is_special_tarif_for_distributor = distributor?.is_special_tarifs
                
                //console.log("createValuesRates", this.modalData.type, this.fieldsTv)
                
                if(this.modalData.type == 'split'){
                    /* Фільтруємо тарифи по послузі ТБ */
                    if(distributor?.is_special_tarifs) this.fieldsTv.find(x => x.name == "tarif").values = this.rates_tv_special
                    else this.fieldsTv.find(x => x.name == "tarif").values = this.rates_tv 
                }else{ 
                    /* Фільтруємо тарифи по подвійній послузі */
                    if(distributor?.is_special_tarifs) this.fieldsTv.find(x => x.name == "tarif").values = this.rates_double_special
                    else this.fieldsTv.find(x => x.name == "tarif").values = this.rates_double 
                }
                 
            },
            eventChangeTarifField(){
                let tarifID = typeof(this.form_data_tv.tarif) == 'object' && this.form_data_tv.tarif.id || this.form_data_tv.tarif
                const tarif = this.rates.find(x => x.id == tarifID)
                if(!tarif) return
                this.priceTarif = Number(tarif.price)
            },
            apllyFiltersTarifs(){
                this.fieldsTv.find(x => x.name == "tarif").values = this.$refs.filtersTarifs[0].rates
            },
            sendForm() {
                if(this.$refs.formDouble.validate()){
                    
                    this.loadingSubmit = true;
                    /* Для Телебачення */
                    let data_send = {
                        date_tarif_change: this.form_data_tv.date_tarif_change,
                        tarif: typeof(this.form_data_tv.tarif) == 'object' && this.form_data_tv.tarif.id || this.form_data_tv.tarif,
                        service: 1,
                    }
                    if(this.modalData.type == 'split') {
                        data_send["next_inet"] = this.$refs.fieldInet.form_data
                        /* bag на тарифі відпустка не відправляється fee - так і не треба ж? */
                        if(this.$refs.fieldInet.form_data.tarif == 38) data_send.next_inet.fee = "0.00"
                    }
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: this.modalData.method,
                        url: `/api/service/change_tarif/${this.abonent.id}/`,
                        data: data_send,
                    })
                    .then(response => {
                        //console.log(response);
                        this.$emit('update-abonent');
                        this.$router.app.$snack.show(response.data);
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                }
                
            },
            
        }
    }
</script>
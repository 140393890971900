<template>
    <v-tooltip bottom color="secondary darken-1" content-class='custom-tooltip'>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="is_nextMonth" style="vertical-align: sub;" :loading="loadingGetIsChangeTarif" class="ml-2" :color="tarifNextData.is_global ? 'blue':'orange'" v-bind="attrs" v-on="on">mdi-information</v-icon>
            <span v-else
            style="cursor: pointer;"
            v-bind="attrs"
            v-on="on"
            >
            {{ tarif && tarif.name }} 
            </span>
        </template>
        
        <div v-if="tarif">
            <template v-if="is_nextMonth">
                <span >Запланована зміна тарифу<br> </span>
                <v-chip small color="blue" outlined class=" my-1" v-if="tarifNextData && tarifNextData.is_global"><v-icon left small color="blue">mdi-information-outline</v-icon>Глобальна зміна тарифу</v-chip>
                <br v-if="tarifNextData && tarifNextData.is_global">
                <v-chip small color="error" outlined class=" my-1" v-if="tarifNextData && tarifNextData.is_job"><v-icon left small color="error">mdi-information-outline</v-icon>Тариф активується після виконання заявки</v-chip>
                <br v-if="tarifNextData && tarifNextData.is_job">
                <span>Тариф з {{ tarifNextData && tarifNextData.date_apply }} :</span> 
            </template>
            <span v-else>Тариф на Поточний місяць:</span>
            
            <div class="pa-0 ma-0" v-for="(tarif__, key) in listTarifs" :key="key + 200">
                <v-divider class="my-2"/>
                <span>
                    <h3><v-icon v-if="listTarifs.length == 2" color="white" class="mr-1 pb-1">{{key == 0 ? 'mdi-television' : 'mdi-web'}}</v-icon>
                    {{ tarif__.name }}</h3>
                </span>
                <br>
                <div class="d-flex justify-space-between">
                    <span class="mr-auto">Вартість тарифу:</span> 
                    <span class="ml-5 text-end">{{ tarif__.price }}</span>
                    <br>
                </div>
                <div v-if="tarif__.price_new != tarif__.price" class="d-flex justify-space-between">
                    <span class="mr-auto">Вартість тарифу з {{ tarif__.date_change }}:</span> 
                    <span class="ml-6 text-end">{{ tarif__.price_new }}</span>
                    <br>
                </div>
                
                <div v-if="tarif__.speed" class="d-flex justify-space-between">
                    <span class="mr-auto">Швидкість:</span> 
                    <span class="ml-5 text-end">{{ tarif__.speed }} Мб/с</span>
                    <br>
                </div>
                <div v-if="tarif__.date_end" class="d-flex justify-space-between">
                    <span class="mr-auto">Дійсний до:</span> 
                    <span class="ml-5 text-end">{{ tarif__.date_end }}</span>
                    <br>
                </div>
                <div >
                <v-chip color="accent" outlined small label v-if="tarif__.promo" class="mr-2 mt-4">Акція</v-chip>
                <v-chip color="accent" outlined small label v-if="tarif__.is_special" class="mr-2 mt-4">Спеціальний</v-chip>
                <v-chip color="accent" outlined small label v-if="tarif__.service == 1 && tarif__.speed" class="mr-2 mt-4">ПП</v-chip>
                <v-chip color="accent" outlined small label v-if="tarif__.package == 1 " class="mr-2 mt-4">УПП</v-chip>
                <v-chip color="accent" outlined small label v-if="tarif__.package == 2 " class="mr-2 mt-4">Стандарт</v-chip>
                <v-chip color="accent" outlined small label v-if="tarif__.real_ip" class="mr-2 mt-4">Real IP</v-chip> 
                </div>
            </div>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    props: ['is_nextMonth', 'tarif', 'tarifNextData', 'loadingGetIsChangeTarif', 'toServiceInet'],
    computed:{
        isSameTarifInet(){
            /* Перевіряємо чи є заплановані зміни тарифу інтернет в базі телебачення */
            
            return this.tarifNextData?.next_inet?.next_tarif || false
        },
        listTarifs(){
            //console.log(this.isSameTarifInet)
            return !this.toServiceInet && this.isSameTarifInet && [this.tarif, this.isSameTarifInet] || [this.tarif] 
        }
    }
}
</script>


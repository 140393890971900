<template>
     <v-col cols="12" sm=6 v-if="true">    
        <div class="mb-4 text-center">
            <h4>{{ title }}</h4>
        </div>
        <!--<template v-if="month != 'next' && monthly_fee && !summForDays && !summNotForDays">
            <v-col cols="12" class="py-2">
            <v-btn small block title="Змінити тариф" color="deep-orange lighten-1" outlined @click="eventChangeTarifField()">
                <v-icon left>mdi-swap-horizontal</v-icon>Змінити тариф
            </v-btn>
            </v-col>
        </template>-->
        <template>
            <v-col v-for="field in fieldsInternet" :key="field.name" cols="12" class="py-0">
                <v-select v-if="field.name == 'tarif'"
                open-on-clear
                :loading="loadingFiltersTarif"
                dense
                outlined
                hide-details="auto"
                :label="field.title"
                v-model="form_data[field.name]"
                :name="field.name"
                :rules="field.rules && textRules || notRules"
                :items="field.values"
                item-text="name"
                item-value="id"
                :disabled="field.disabled"
                class="py-2"
                @change="eventChangeTarifField()"
                >
                    <template v-if="parent_dialog" v-slot:prepend-item>
                        <v-subheader style="height: 30px;">Фільтри:</v-subheader>
                        <FiltersTarifs ref="filtersTarifs" v-on:create-values-rates="createValuesRates()" v-on:aplly-filters-tarifs="apllyFiltersTarifs()" :is_special_tarif_for_distributor="is_special_tarif_for_distributor" service="inet"/>
                        <v-divider />
                    </template>
                    <template v-if="field.name == 'tarif'" v-slot:item="{ item }">
                        <div style="width: 100%;" class="d-flex justify-space-between">
                          <span class="v-list-item__title">{{ item.name }}</span>
                          <DetailTarifForFilters :tarif="item" />
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <v-row justify="center" class="mt-2 mb-2" >
                            <small class="error--text">Тарифи не знайдено</small>
                        </v-row>
                    </template>
                </v-select>
                
                <v-text-field v-else-if="field.name == 'price'"
                :readonly="!discountActive"
                dense
                outlined
                hide-details="auto"
                :label="field.title"
                v-model="form_data.price"
                name="price"
                :rules="field.rules && numberRules || notRules" 
                type="number"
                :disabled="field.disabled"
                :min="0"
                class="py-2"
                @input="eventChangePriceField()"
                >
                <template v-slot:append>     
                    <v-icon color="primary lighten-1" v-if="!discountActive" @click.stop.prevent="discountActive = true, eventChangeTarifField()" title="Додати знижку">mdi-percent-circle</v-icon>
                </template>
                </v-text-field>
                
                <div v-else-if="field.name == 'discount'" :class="{'d-none py-0': !discountActive}" @mouseover="mouseoverBlock = true" @mouseleave="mouseoverBlock = false">
                <v-text-field 
                
                dense
                outlined
                hide-details="auto"
                :label="field.title"
                v-model="form_data.discount"
                name="discount"
                :rules="field.rules && numberRules || notRules"
                type="number"
                :disabled="field.disabled"
                :min="-100"
                :max="100"
                class="py-2"
                @input="eventChangeDiscountField()"
                >
                <template v-slot:append>     
                    <v-icon @click="discountActive = false, eventChangeTarifField()" title="Прибрати знижку">mdi-close</v-icon>
                </template>
                </v-text-field>
                <!--<v-row justify="center" class="mt-0 mx-0" :style="{'visibility': mouseoverBlock && 'visible' || 'hidden'}">
                    <v-chip-group column>
                    <v-chip small @click="form_data.discount = '0', eventChangeDiscountField()" key="tag1">0 %</v-chip>
                    <v-chip small @click="form_data.discount = 50, eventChangeDiscountField()" key="tag3">50 %</v-chip>
                    <v-chip small @click="form_data.discount = 100, eventChangeDiscountField()" key="tag5">100 %</v-chip>
                    </v-chip-group>
                </v-row>
                -->
                </div>
                <v-checkbox v-else-if="field.name == 'for_days'"
                    dense
                    v-model="form_data.for_days"
                    :disabled="field.disabled"
                    :label="field.title"
                    hide-details="auto"
                    :class="{'d-none': month == 'next'}"
                    class="mt-0 mb-0 py-2"
                    @change="getSummForDays(true)"
                ></v-checkbox>

                <template v-else-if="field.name == 'fee'">
                    <v-text-field v-if="form_data.for_days"
                    title="Сума до сплати вираховується з поточного дня до кінця місяця, без врахування залишку на рахунку."
                    background-color="accent darken-1"
                    light
                    filled
                    single-line
                    hide-details="auto"
                    v-model="summForDays"
                    name="summ_fee"
                    :rules="field.rules && textRules || notRules"
                    :type="field.type"
                    readonly
                    rounded
                    prefix="До сплати по днях:  "
                    class="py-2"
                    :class="{'d-none': month == 'next' || !summForDays || !form_data.fee, 'custom_prefix_field_ligth' : !$vuetify.theme.dark, 'custom_prefix_field_dark' : $vuetify.theme.dark}"
                    >
                    </v-text-field>
                    <v-text-field v-else
                    background-color="accent darken-1"
                    
                    light
                    
                    filled
                    single-line
                    hide-details="auto"
                    v-model="summNotForDays"
                    name="summ_fee"
                    :rules="field.rules && textRules || notRules"
                    :type="field.type"
                    readonly
                    rounded
                    prefix="Сума до сплати:  "
                    
                    class="py-2"
                    :class="{'d-none': month == 'next' || !form_data.price, 'custom_prefix_field_ligth' : !$vuetify.theme.dark, 'custom_prefix_field_dark' : $vuetify.theme.dark}"
                    >
                    </v-text-field>
                </template>
            </v-col>
            <template v-if="month == 'next' && showBtnSpliceTarifs">
            <v-col cols="12" class="py-6">
            <v-btn small block title="Копіювати зміни поточного тарифу у тариф наступного місяця" color="deep-orange lighten-1" outlined @click="spliceTarifs()">
                <v-icon left>mdi-swap-horizontal</v-icon>Копіювати з поточного
            </v-btn>
            </v-col>
        </template>
        </template>
    </v-col>
</template>

<script>
//import axios from "axios"
import { mapGetters } from "vuex"
import DetailTarifForFilters from '@/components/AbonentInfo/TabInfoCards/DetailTarifForFilters.vue'
export default {
    props: ['title', 'tarif', 'month', 'operator_id', "isChangeNowTarif", 'parent_dialog'],
    components: {
        DetailTarifForFilters
    },
    data: () => ({
        showBtnSpliceTarifs: false,
        is_special_tarif_for_distributor: false,
        discountActive: false,
        loadingFiltersTarif: false,
        mouseoverBlock: false,
        defaultFormData:{
            price: 0,
            discount: 0,
            for_days: true
        },
        form_data: {},
        summForDays: null,
        summNotForDays: null,
        fieldsInternet: [
            {title: "Тариф",           name: "tarif",    rules: true,  type: "select",   value: "", disabled: false, values: []},
            {title: "Сума абонплати ( грн. )", name: "price",    rules: true,  type: "number",   value: "", disabled: false, min: 0, values: []},
            {title: "Знижка %",        name: "discount", rules: true,  type: "number",   value: "", disabled: false, min: 0, max: 100},
            {title: "Розрахунок абонплати по днях", name: "for_days", rules: false,  type: "checkbox",   value: "", disabled: false, },
            {title: "Сума до сплати",  name: "fee", rules: false,  type: "text",   value: "", disabled: true },

        ],
        textRules: [
            v => !!v || "Це поле обов'язкове",
        ],
        numberRules: [
            v => !!v || v === 0 || "Це поле обов'язкове",
        ],
        notRules: [
            v => !!v || true ,
        ],
    }),
    computed: {
        ...mapGetters({
            rates_inet: "filters/rates_inet",
            rates_inet_special: "filters/rates_inet_special",
            distributors: "filters/operators",  
        }),
        monthly_fee(){
            return this.service_inet_billing?.balance_additional?.monthly_fee != 0
        }
        
    },
    watch:{
        //
    },
    mounted(){
        console.log("mounted FieldsTarif")
        
        this.form_data = JSON.parse(JSON.stringify(this.defaultFormData))
        this.show()
    },
    methods: {
            createValuesRates(){
                const distributor = this.distributors.find(x => x.id == this.operator_id)
                this.is_special_tarif_for_distributor = distributor?.is_special_tarifs
                if(distributor?.is_special_tarifs) this.fieldsInternet.find(x => x.name == "tarif").values = this.rates_inet_special
                else this.fieldsInternet.find(x => x.name == "tarif").values = this.rates_inet
            },
            show(){
              this.showBtnSpliceTarifs = false
              this.summForDays = null
              this.summNotForDays = null
              this.is_special_tarif_for_distributor = false
              this.discountActive = this.tarif.discount && this.tarif.discount != 0
              this.listFiltersTarif = []
              this.filterSpeedData = null
              this.createValuesRates()
              this.form_data = JSON.parse(JSON.stringify(this.defaultFormData))
              this.form_data.tarif = this.tarif.id
              this.form_data.price = this.tarif.fee
              this.form_data.discount = this.tarif.discount

              
            },
            percentageDifference(a, b) {
              var difference = a - b;
              var percentage = (difference / a) * 100;
              return percentage.toFixed(2);
            },
            differenceFromPercentage(number, percentage) {
              var amount = (percentage / 100) * number;
              var difference = number - amount;
              return difference.toFixed(2);
            },
            eventChangeTarifField(){
                const tarif = this.rates_inet_special.find(x => x.id == this.form_data.tarif)
                if(!tarif) return
                this.form_data.price = Number(tarif.price)
                this.form_data.discount = 0
                this.getSummForDays()
            },
            eventChangePriceField(){
                const tarif = this.rates_inet_special.find(x => x.id == this.form_data.tarif)
                if(!tarif) return
                const priceTarif = Number(tarif.price)
                this.form_data.discount = this.percentageDifference(priceTarif, this.form_data.price)
                this.getSummForDays()
            },
            eventChangeDiscountField(){
                const tarif = this.rates_inet_special.find(x => x.id == this.form_data.tarif)
                if(!tarif) return
                const priceTarif = Number(tarif.price)
                this.form_data.price = this.differenceFromPercentage(priceTarif, this.form_data.discount)
                this.getSummForDays()
            },
            getSummForDays(checkbox=false){
                if (this.month == "next") {
                    this.$emit("change-tarif-data")
                    return
                }
                const daysInMonth = new Date().daysInMonth()
                const nowDayInMonth = new Date().getDate()
                let summForDays = Number(this.form_data.price) / daysInMonth * (daysInMonth - nowDayInMonth + 1)
                this.form_data.fee = summForDays.toFixed(2)
                this.summForDays = summForDays.toFixed(2) + ' грн.'
                this.summNotForDays = this.form_data.price + ' грн.'
                
                !checkbox && this.$emit("change-tarif-data")
            },
            apllyFiltersTarifs(){
                this.fieldsInternet.find(x => x.name == "tarif").values = this.$refs.filtersTarifs[0].rates
            },
            spliceTarifs(){
                this.$emit('splice-tarifs')
                this.showBtnSpliceTarifs = false
                
            }
        }
}
</script>
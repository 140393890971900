<template>
    <v-dialog
            v-model="dialog"
            max-width="380"
            @keydown.esc="cancel"
            >
            <v-card>
            <v-card-title class="text-h5 text-center">
                Зверніть увагу!
            </v-card-title>

            <v-card-text class="pt-3 text-center">
                
                <template v-if="modalType == 'double'">
                    <span v-if="isNextTarifInetBilling">
                        <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>
                        На наступний місяць вже запланована зміна тарифу інтернет. Поточні дані зміни тарифу будуть перезаписані, якщо ви обʼєднаєте послуги.
                    <br><br>
                    </span>
                    
                    <span v-if="isPlanJoinDoubleService">
                        <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>
                        Об'єданння послуг вже заплановане. Перед продовженням скасуйте заплановані зміни тарифів в меню сервісу телебачення.
                    </span>
                    <span v-else-if="isNextTarifTv">
                        <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>
                        Розʼєднання послуг вже заплановане. Перед продовженням скасуйте заплановані зміни тарифів в меню сервісу телебачення.
                    </span>
                    
                </template>
                <template v-if="modalType == 'tv'">
                    <span v-if="isPlanJoinDoubleService || isNextTarifTv">
                        <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>
                        У абонента вже є заплановані зміни тарифу. Новими діями ви перезапишите заплановані зміни.
                    </span>
                </template>
                <template v-if="modalType == 'inet'">
                    <span v-if="isPlanJoinDoubleService">
                        <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>
                        У абонента запланована зміна тарифу на подвійну послугу. Поточні зміни будуть перезаписані під час активації подвійної послуги.
                    </span>
                </template>
                    
                
            </v-card-text>

            <v-card-actions>
                <v-btn
                color="deep-orange"
                text
                @click.native="cancel"
                >
                Вийти
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                :class="{'d-none': modalType == 'double' && (isPlanJoinDoubleService || isNextTarifTv)}"
                color="primary lighten-1"
                text
                @click.native="agree"
                >
                Продовжити
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>

export default {
    props: ["service_tv", "service_inet_billing", "checkDoubleTarif"],
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      modalType: null,
    }),
    computed:{
        isNextTarifInetBilling(){
            return this.service_inet_billing?.tarif.id != this.service_inet_billing?.tarif_next?.id || this.service_inet_billing?.tarif.discount != this.service_inet_billing?.tarif_next?.discount
        },
        isPlanJoinDoubleService(){
            let next_tarif = null
            let check_next_tarif_double = false
            if(this.service_tv?.change_tarif && this.service_tv.change_tarif[0]?.next_tarif){
                next_tarif = this.service_tv.change_tarif[0]
                check_next_tarif_double = next_tarif?.next_tarif.service == 1 && next_tarif?.next_tarif.speed != 0 && true || false
            }
            return check_next_tarif_double && next_tarif || false
        },
        isNextTarifTv(){
            let next_tarif = null
            if(this.service_tv?.change_tarif && this.service_tv.change_tarif[0]?.next_tarif){
                next_tarif = this.service_tv.change_tarif[0]
            }
            return next_tarif || false
        },
        isNextTarifTvGlobal(){
            let next_tarif = null
            if(this.service_tv?.change_tarif && this.service_tv.change_tarif[0]?.next_tarif){
                next_tarif = this.service_tv.change_tarif[0]
            }
            console.log(next_tarif?.is_global)
            return next_tarif?.is_global || false
        }
        
    },
    methods:{
        open(modalType) {
            this.modalType = modalType
            if(this.isNextTarifTvGlobal) return true
            if(modalType == 'double'){
                if(this.checkProblemsOfJoinDoubleService()){
                    this.dialog = true
                }else return true
            }else if(modalType == 'tv'){
                if(this.isPlanJoinDoubleService || this.isNextTarifTv){
                    this.dialog = true
                }else return true
            }else if(modalType == 'inet'){
                if(this.isPlanJoinDoubleService){
                    this.dialog = true
                }else return true
            }
            

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
            this.modalType = null
        },
        checkProblemsOfJoinDoubleService(){
            return this.isNextTarifInetBilling || this.isPlanJoinDoubleService || this.isNextTarifTv
        },
    }
}
</script>
